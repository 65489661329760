//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { instMeta } from '@/data/meta-inst'
import cursos from '@/data/cursos.js'

export default {
  data() {
    return {
      showVideo: false,
      cursos,
      // isActive: false,
    }
  },
  head() {
    return instMeta()
  },
  computed: {
    cursosLivres() {
      return this.cursos.filter((c) => c.categoria === 'Curso')
    },
    cursosPos() {
      return this.cursos
        .filter((c) => c.categoria === 'Pós-graduação')
        .filter((c) => c.listar !== false)
    },
  },
  methods: {
    scrollIntoView(id) {
      const yOffset = -60
      const element = document.querySelector(id)
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset

      window.scrollTo({ top: y, behavior: 'smooth' })
    },
  },
  mounted() {
    setTimeout(() => {
      new RDStationForms(
        'saiba-mais-sobre-nossos-cursos-68d48e13ff33a94d5932',
        'UA-228576147-1'
      ).createForm()
    }, 500)
  },
}
