//
//
//
//
//
//
//
//

import * as b from '@/static/blog.json'

let blog = b.default

export default {
	computed: {
		blogs() {
			let blogs = []
			blog.forEach((element) => {
				blogs.push(element)
			})
			let qtdd = this.$sc.isDesktop ? 3 : 4
			return blogs.slice(0, qtdd)
		},
	},
}
