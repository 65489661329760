export const instMeta = () => ({
  title: "Multiversa",
  link: [
    {
      rel:"preload",
      as:"image",
      href: "https://ik.imagekit.io/cyrcl27sn/banners/form.jpg"
    },
    {
      rel:"canonical",
      href: "https://multiversa.com/home/"
    },
  ],
  meta: [
    {
      hid: "https://multiversa.com/home/",
      name: "Multiversa",
      content: "Sua evolução passa por aqui! Venha conhecer uma nova forma de aprender com projetos reais e aprendizagem ativa.",
      keywords: "educacao, multiversa"
    },
    {
      name: "thumbnail",
      content: "https://ik.imagekit.io/cyrcl27sn/banners/form.jpg"
    },
    { name:"type", content: "website"},
    {property: "fb:app_id", hid: "fb:app_id", name:"fb:app_id", content: "390367489652187", },
    {property: "og:title", hid: "og:title", name:"og:title", content: "Multiversa", },
    {property: "og:description", hid: "og:description", name:"og:description", content: "Sua evolução passa por aqui! Venha conhecer uma nova forma de aprender com projetos reais e aprendizagem ativa." },
    {property: "og:site_name", hid: "og:site_name", name:"og:site_name", content: "Multiversa"},
    {property: "og:type", hid: "og:type", name:"type", content: "website"},
    {property:"og:image:secure_url", hid: "og:image:secure_url", itemprop:"image", name: "og:image:secure_url", content: "https://ik.imagekit.io/cyrcl27sn/banners/form.jpg" },
    {property: "og:image", hid: "og:image", itemprop:"image", name: "og:image", content: "https://ik.imagekit.io/cyrcl27sn/banners/form.jpg" },
    {property: "og:image:type", hid: "og:image:type", name: "og:image:type", content: "image/jpg" },
    {property: "og:url", hid: "og:url", name: "og:url", content: "https://multiversa.com/" },
    
    {property: "twitter:card", hid: "twitter:card", name:"twitter:card",  content:"summary_large_image" },
    {property: "twitter:title", hid: "twitter:title", name:"twitter:title", content: "Multiversa", },
    {property: "twitter:description", hid: "twitter:description", name:"twitter:description", content: "Sua evolução passa por aqui! Venha conhecer uma nova forma de aprender com projetos reais e aprendizagem ativa." },
    
    
    {name: "robots", content: "index,follow"}
  ]
})

