//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import flick from '@/mixins/Flickity.js'
export default {
  mixins: [flick],
  props: ['blogs'],
  data() {
    return {
      doFlick: true,
      itemsCount: 5,
      height: null,
    }
  },

  methods: {
    setHeight() {
      let elements = Array.from(
        document.querySelectorAll('.blog-section .v-card')
      )
      if (elements.length) {
        let heights = elements.map((e) => e.offsetHeight)
        this.height = Math.max(...heights) + 5 + 'px'
      }
    },
  },

  watch: {
    $route() {
      setTimeout(() => {
        this.setHeight()
      }, 1000)
    },
  },

  mounted() {
    this.setHeight()
  },
}
