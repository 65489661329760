//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      modalContato: false,
      formMounted: false,
      rd: null,
    }
  },
  watch: {
    modalContato(newValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.rd = new RDStationForms(
            'entre-em-contato-home-d264b81835d059c9bb3b',
            'UA-228576147-1'
          )
          this.rd.createForm()
        }, 100)
        setTimeout(() => {
          this.formMounted = true
        }, 700)
      }
    },
  },
  methods: {
    resetForm() {
      document
        .getElementById('form-entre-em-contato-home-d264b81835d059c9bb3b')
        .remove()
      this.modalContato = false
      this.formMounted = false
    },
  },
}
